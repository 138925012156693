// Responsive
@media screen and (min-width: 1024px) {
  .bg-hover:hover {
    background-color: rgba(255, 255, 255, 0.1);
    color: #ffffff;
  }
}
.mb-main-content {
  border-left: 1px solid #e2e2e2;
  width: calc(100vw - 250px);
  @media screen and (max-width: 767px) {
    border-left: 0 none;
    width: 100vw;
  }
  @media screen and (max-width: 1024px) {
    width: 100vw;
  }
}
.mb-custom-content {
  width: calc(100vw - 250px);
  @media screen and (max-width: 767px) {
    border-left: 0 none;
    width: 100vw;
  }
  @media screen and (max-width: 1024px) {
    width: 100vw;
  }
}
@media (max-width: 500px) {
  .ant-modal-body {
    padding: 10px;
  }
  .media-modal {
    width: 100% !important;
    top: 10px;
  }
  .import-tracking {
    max-width: 95% !important;
  }
}
