@import "./variables.scss";

// CONTAINER

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

// STYLES

.hidden {
  display: none;
}

.focus\:outline-none:focus {
  outline: 0;
}

// FONT WEIGHT
.font-medium {
  font-weight: 500;
}

// DISPLAY

.block {
  display: block;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-end {
  justify-content: flex-end;
}

.flex-1 {
  flex: 1;
}

.flex-col {
  flex-direction: column;
}

.bg-white {
  background: #fff;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.w-full {
  width: 100%;
}

.rounded {
  border-radius: 0.25rem;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.rounded-l-lg {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.rounded-r-lg {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.h-auto {
  height: auto;
}

.bg-cover {
  background-size: cover;
}

.bg-gray-400 {
  background-color: $color-gray;
}

.text-2xl {
  font-size: 1.5rem;
}

.text-center {
  text-align: center;
}

.align-baseline {
  vertical-align: baseline;
}

.text-sm {
  font-size: 0.875rem;
}

.justify-around {
  justify-content: space-around;
}

.text-blue-500 {
  color: $color-blue;
}

.w-12 {
  width: 3rem;
}
.w-12 {
  height: 3rem;
}

@for $w from 1 to 3 {
  .w-#{$w}\/3 {
    width: $w * 100%/3;
  }
}

@for $w from 1 to 2 {
  .w-#{$w}\/2 {
    width: $w * 100%/2;
  }
}

@for $w from 1 to 5 {
  .w-#{$w}\/5 {
    width: $w * 100%/5;
  }
}

@for $m from 0 to 10 {
  .mx-#{$m} {
    margin-left: $m/ 4 + rem;
    margin-right: $m/ 4 + rem;
  }
  .my-#{$m} {
    margin-top: $m/ 4 + rem;
    margin-bottom: $m/ 4 + rem;
  }
  .m-#{$m} {
    margin: $m/ 4 + rem;
  }
  .mt-#{$m} {
    margin-top: $m/ 4 + rem;
  }
  .mr-#{$m} {
    margin-right: $m/ 4 + rem;
  }
  .mb-#{$m} {
    margin-bottom: $m/ 4 + rem;
  }
  .ml-#{$m} {
    margin-left: $m/ 4 + rem;
  }
  .p-#{$m} {
    padding: $m/ 4 + rem;
  }
  .pt-#{$m} {
    padding-top: $m/ 4 + rem;
  }
  .pr-#{$m} {
    padding-right: $m/ 4 + rem;
  }
  .pb-#{$m} {
    padding-bottom: $m/ 4 + rem;
  }
  .pl-#{$m} {
    padding-left: $m/ 4 + rem;
  }
  .px-#{$m} {
    padding-left: $m/ 4 + rem;
    padding-right: $m/ 4 + rem;
  }
  .py-#{$m} {
    padding-top: $m/ 4 + rem;
    padding-bottom: $m/ 4 + rem;
  }
}
@media (min-width: 640px) {
  @for $m from 0 to 12 {
    .sm\:m-#{$m} {
      margin: $m/ 4 + rem;
    }
    .sm\:mt-#{$m} {
      margin-top: $m/ 4 + rem;
    }
    .sm\:mr-#{$m} {
      margin-right: $m/ 4 + rem;
    }
    .sm\:mb-#{$m} {
      margin-bottom: $m/ 4 + rem;
    }
    .sm\:ml-#{$m} {
      margin-left: $m/ 4 + rem;
    }
    .sm\:p-#{$m} {
      padding: $m/ 4 + rem;
    }
    .sm\:pt-#{$m} {
      padding-top: $m/ 4 + rem;
    }
    .sm\:pr-#{$m} {
      padding-right: $m/ 4 + rem;
    }
    .sm\:pb-#{$m} {
      padding-bottom: $m/ 4 + rem;
    }
    .sm\:pl-#{$m} {
      padding-left: $m/ 4 + rem;
    }
  }
  .sm\:w-1\/2 {
    width: 50%;
  }
}
// SCREEN MD
@media (min-width: 768px) {
  @for $w from 1 to 3 {
    .md\:w-#{$w}\/3 {
      width: $w * 100%/3;
    }
  }
  .md\:flex {
    display: flex;
  }
  .md\:justify-between {
    justify-content: space-between;
  }
  .md\:mr-2 {
    margin-right: 0.5rem;
  }
  .md\:mb-0 {
    margin-bottom: 0;
  }
}

// SCREEN LG
@media (min-width: 1024px) {
  .lg\:inline-block {
    display: inline-block;
  }
  .lg\:block {
    display: block;
  }
  .lg\:w-11\/12 {
    width: 91.666667%;
  }
  .lg\:w-1\/2 {
    width: 50%;
  }
  .lg\:block {
    display: block;
  }
  .lg\:hidden {
    display: none;
  }
  .lg\:w-5\/12 {
    width: 41.666667%;
  }
  .lg\:w-11\/12 {
    width: 91.666667%;
  }
  .lg\:w-7\/12 {
    width: 58.333333%;
  }
  .lg\:block {
    display: block;
  }

  @for $m from 0 to 12 {
    .lg\:m-#{$m} {
      margin: $m/ 4 + rem;
    }
    .lg\:mt-#{$m} {
      margin-top: $m/ 4 + rem;
    }
    .lg\:mr-#{$m} {
      margin-right: $m/ 4 + rem;
    }
    .lg\:mb-#{$m} {
      margin-bottom: $m/ 4 + rem;
    }
    .lg\:ml-#{$m} {
      margin-left: $m/ 4 + rem;
    }
    .lg\:p-#{$m} {
      padding: $m/ 4 + rem;
    }
    .lg\:px-#{$m} {
      padding-left: $m/ 4 + rem;
      padding-right: $m/ 4 + rem;
    }
    .lg\:pt-#{$m} {
      padding-top: $m/ 4 + rem;
    }
    .lg\:pr-#{$m} {
      padding-right: $m/ 4 + rem;
    }
    .lg\:pb-#{$m} {
      padding-bottom: $m/ 4 + rem;
    }
    .lg\:pl-#{$m} {
      padding-left: $m/ 4 + rem;
    }
  }
}

.font-bold {
  font-weight: bold;
}

.text-right {
  text-align: right;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.ant-input[disabled],
.ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  color: rgba(0, 0, 0, 0.6);
}
