@import "./variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap");
@import "~react-image-gallery/styles/scss/image-gallery.scss";

body {
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f0f2f5;
}

// Global

.text-color {
  color: $text-color;
}

// Header
#header {
  position: relative;
  background-color: #fff;
  display: flex;
  flex-wrap: nowrap;
  box-shadow: 0 1px 5px 0 rgba(41, 85, 115, 0.21);
  z-index: 2;
  padding: 8px 0px;

  .header-left {
    min-width: 250px;
    .ml-20 {
      margin-left: 20px;
    }
    .header-brand-name {
      color: #fff;
      margin-left: 10px;
    }

    @media screen and (max-width: 1024px) {
      width: 50px;
    }
  }

  .header-right {
    width: calc(100% - 250px);

    @media screen and (max-width: 1024px) {
      width: calc(100% - 20px);
    }
  }

  .header-search {
    width: 96%;
    display: none;
  }
  &.header-sticky {
    position: sticky;
    top: 0;
  }
}

.top-menu {
  padding: 0 12px;

  a {
    color: #2f3b47;
    padding: auto;
  }

  .ant-menu-submenu-title {
    padding-left: 0.5rem !important;
    // padding-right: rem !important;
  }

  .ant-menu-submenu-arrow {
    display: none;
  }
}

.top-menu-without-child {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.menu-mobile-wrap {
  button {
    color: #fff;
    background: transparent;
    border: 0 none;
  }

  .menu-mobile {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1060;
    box-shadow: 3px 0 7px 0 rgba(41, 85, 115, 0.21);
    transform: translateX(-500px);
    -o-transform: translateX(-500px);
    -webkit-transform: translateX(-500px);
    -moz-transform: translateX(-500px);
    transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -webkit-transition: all 0.5s ease-out;

    &.show-menu {
      transform: translateX(0px);
      -o-transform: translateX(0px);
      -webkit-transform: translateX(0px);
      -moz-transform: translateX(0px);
    }
    .ant-menu-submenu > .ant-menu {
      background: #fff;
    }
  }
}

// .header-search {
//   > input {
//     background-color: $second-color;
//     border: none;
//     color: #ffffff;

//     &:focus {
//       border: none;
//       -box-shadow: none;
//       background-color: #ffffff;
//       color: #000;
//     }
//   }

//   .ant-input-suffix {
//     font-size: 18px;
//     font-weight: 600;

//     i {
//       color: #bfbfbf;
//     }
//   }
// }

.ant-card-bordered,
.ant-card-head,
.ant-input,
.ant-select-selection,
.ant-input-group-addon {
  border-color: rgb(195, 206, 217);
}

.ant-card .ant-table-pagination.ant-pagination {
  margin-right: 15px;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-pagination-item {
  border-color: rgb(223, 229, 237);
}

.ant-menu-submenu > .ant-menu {
  background: #f0f2f5;
}

// a {
//   color: rgb(122, 135, 150);

//   &:hover {
//     color: darken(rgb(122, 135, 150), 5);
//   }
// }

.ant-menu .ant-menu-item,
.ant-menu .ant-menu-submenu-title,
.ant-menu .ant-menu-submenu-inline > .ant-menu-submenu-title {
  font-weight: 500;
}

.submenu-title-wrapper-avatar {
  display: none !important;
}

@media (min-width: 1024px) {
  .submenu-title-wrapper-avatar {
    display: flex !important;
    align-items: center !important;
  }
}

.header-menu {
  &.ant-menu-horizontal {
    background-color: transparent;
    border-bottom: none;

    .ant-menu-submenu {
      border-bottom: none;

      &:hover {
        border-bottom: none;
      }
    }

    .ant-menu-submenu-title {
      margin-left: 13px;
      padding: 0;
    }

    .icon-before {
      font-size: 22px;
    }

    .icon-arrow {
      font-size: 12px;
    }
  }
}

.ant-menu.ant-menu-vertical.ant-menu-sub {
  padding: 0.1rem 0.75rem;
}

// Sidebar
.ant-menu {
  .ant-menu-item,
  .ant-menu-submenu-title,
  .ant-menu-submenu-inline > .ant-menu-submenu-title {
    border-radius: 0.25rem;

    &:hover,
    &:focus {
      color: $hover-color;
      background: #e4e6eb;

      .ant-menu-submenu-arrow {
        &::before,
        &::after {
          background: $hover-color;
        }
      }

      .text-color {
        color: $hover-color;
      }
    }
  }

  .ant-menu-item-selected > a {
    color: $hover-color;

    &:hover {
      color: $hover-color;
    }
  }

  .ant-menu-item > a {
    color: $text-color;
  }

  &.ant-menu-inline .ant-menu-item::after {
    border-right: 0;
  }

  &.sidebar {
    // padding-top: 0 !important;
    height: 100vh;
    width: 250px !important;
    padding-top: 0.75rem;
    background: #f0f2f5;
    &.sidebar-mobile {
      padding-top: 0;
      background: #fff;
      .logo-mobile {
        background: #fff !important;
        width: 100%;
      }
    }
    .logo-mb {
      background: rgb(45, 54, 72);
      padding-left: 1rem !important;
      border-radius: 0;
      border: 0;

      &:hover {
        background: rgb(45, 54, 72) !important;
      }
    }

    .ant-menu-submenu {
      margin: 2px 0px;

      .ant-menu-sub {
        margin-top: 5px;
      }
    }

    .ant-menu-submenu-title {
      height: 42px;
      margin-top: 0;
      margin-bottom: 0;
      // line-height: 32px;
      display: flex;
      align-items: center;
      font-weight: 500;
      color: #2f3b47;
      font-size: 16px;
    }

    .top-menu-without-child {
      height: 42px;
      margin-top: 0;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      font-weight: 500;
      padding: 0 12px !important;
      width: calc(100% - 16px);
      margin: 0 0.5rem;
      span {
        font-size: 16px;
      }
    }

    .menu-child-item {
      height: 32px;
      margin-top: 0;
      margin-bottom: 0;
      font-weight: 500;
      line-height: 32px;
      padding: 0 0.5rem !important;
      margin: 0 30px;
      width: calc(100% - 30px);
    }

    .ant-menu-item:hover {
      background: #e4e6eb;
    }
  }

  .ant-menu-item-selected,
  .ant-menu-submenu-selected {
    color: $hover-color;
  }

  .ant-menu-submenu-open,
  .ant-menu-item-selected,
  .ant-menu-submenu-selected {
    color: $hover-color;

    > span.text-color {
      color: $hover-color;
    }

    .ant-menu-submenu-title {
      span > .text-color {
        color: $hover-color;
      }

      .ant-menu-submenu-arrow {
        &::before,
        &::after {
          background: $hover-color;
        }
      }
    }
  }

  &:not(.ant-menu-horizontal) .ant-menu-item-selected.ant-menu-item {
    background-color: rgba(45, 151, 255, 0.1);

    > a {
      font-weight: 600;
    }
  }
}

ul.ant-menu-inline {
  border: 0 none;
}

.ant-card.ant-card-bordered {
  .ant-card-head {
    padding: 0 15px;
    min-height: 40px;

    .ant-card-head-title,
    .ant-card-extra {
      padding: 10px 0;
    }
  }
}

.ant-card-bordered {
  border: none;
  background-color: var(--p-surface, #fff);
  //box-shadow: var(--p-card-shadow,0 0 0 1px rgba(63, 63, 68, 0.05),0 1px 3px 0 rgba(63, 63, 68, 0.15));
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  outline: 0.1rem solid transparent;
}

.ant-table-default {
  border: 1px solid #e2e8f0;
  border-bottom: 0 none;

  .ant-table-thead > tr > th {
    padding: 12px 15px;
  }

  .ant-table-tbody > tr > td {
    padding: 10px 15px;
  }
}

.ant-menu .ant-menu-item > a {
  color: $text-color;
  font-weight: 500;
}

.ant-upload-list-item-actions .anticon-download {
  display: none;
}

.ant-popover {
  z-index: 1051;
}

.ant-select-dropdown {
  z-index: 1051;
}

.ant-input-suffix {
  margin-bottom: auto;
  margin-top: auto;
}

.ant-table-thead > tr > th {
  border-top: 1px solid rgb(223, 229, 237);
}

.ant-pagination-item-active a {
  cursor: no-drop;
  color: rgba(0, 0, 0, 0.65) !important;
  &:hover,
  &:focus {
    color: rgba(0, 0, 0, 0.65);
  }
}

.ant-pagination-item-active {
  background: #eee !important;
}
.ant-pagination-item-active:focus,
.ant-pagination-item-active:hover {
  border-color: #ddd;
}

hr {
  border-bottom: 0;
  border-top: 1px solid #d9d9d9;
}

.ant-upload-drag-container {
  .description {
    font-size: 12px;
  }
}

.ant-tabs-content {
  outline: none;
  .ant-tabs-tabpane {
    outline: none;
  }
}

// disable custom image editor
// Filter order
.order-tabs {
  .ant-tabs-tab {
    &:last-child {
      margin-right: 32px;
    }
  }
}

.mb-filter-collapse {
  position: relative;
  .ant-collapse-extra {
    .ant-tag {
      background-color: #dfe3e8;
      color: #212b36;
      font-weight: 500;
      border: none;
      line-height: 25px;
      padding: 0 0.6rem;
      font-size: 13px;
      border-radius: 2rem;
      margin-top: 5px;
    }
  }
  &.ant-collapse-item-active {
    .ant-collapse-header {
      background-image: linear-gradient(
        rgba(223, 227, 232, 0.3),
        rgba(223, 227, 232, 0.3)
      );
    }
    .ant-collapse-extra {
      display: none;
    }
  }
  &.mb-ant-collapse-item-active {
    .ant-collapse-header {
      background-image: linear-gradient(
        rgba(223, 227, 232, 0.3),
        rgba(223, 227, 232, 0.3)
      );
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        border-right: 2px solid #1890ff;
        transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
          opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
          -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
        transform: scaleY(1);
      }
    }
  }
  .ant-collapse-header:hover {
    background-image: linear-gradient(
      rgba(223, 227, 232, 0.3),
      rgba(223, 227, 232, 0.3)
    );
  }
}

.ant-collapse > .ant-collapse-item {
  & > .ant-collapse-header {
    .ant-collapse-extra {
      float: left;
    }
  }
  &.mb-filter-collapse {
    .ant-collapse-arrow {
      top: 25px;
    }
  }
}

.mb-filter-tag {
  background-color: #dfe3e8;
  color: #212b38;
  border-radius: 5px;
  .anticon-close {
    font-size: 14px;
    margin-left: 7px;
    color: rgba(0, 0, 0, 0.65);
  }
}

.ant-table-tbody > tr.no-border {
  & > td {
    border: none;
    &:first-child {
      padding-left: 0px;
    }
  }
}

.ant-collapse-icon-position-right > .ant-collapse-item {
  border-color: #f0f0f0;
  > .ant-collapse-header {
    padding-left: 24px;
    padding-top: 16px;
    padding-bottom: 16px;
    .ant-collapse-arrow {
      right: 20px;
    }
  }
  &:last-child {
    border-bottom: none;
  }
}

.ant-drawer-header {
  padding: 19px 24px;
  border-color: #dfe3e8;
}

// Option group
.ant-select-item-group {
  color: rgba(0, 0, 0, 0.75);
  font-size: 14px;
  cursor: default;
  font-style: italic;
  font-weight: 600;
}

.ant-drawer-body {
  padding-top: 10px;
  .ant-collapse-borderless {
    & > .ant-collapse-item {
      border-bottom: 0px;
    }
  }
  .ant-collapse-item {
    .ant-collapse-content {
      .ant-collapse-content-box {
        padding: 20px 21px 20px 24px;
      }
    }
  }
  .date-picker,
  .tracking-status {
    display: block;
    .ant-radio-wrapper {
      &:first-child {
        .ant-radio + span {
          padding-top: 0px;
        }
      }
      &:last-child {
        .ant-radio + span {
          padding-bottom: 0px;
        }
      }
      span.ant-radio + * {
        padding: 5px 8px;
        display: inline-block;
      }
    }
  }
  .ff-input {
    .ant-select-selector {
      border-color: #40a9ff;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }
  }
  .order-type {
    @extend .date-picker;
    .ant-radio-wrapper {
      display: block;
    }
  }
}

.btn-screen-options {
  margin-left: auto;
  margin-bottom: 15px;
  outline: 0.1rem solid transparent;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-width: 0px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  &:hover,
  &:focus,
  &:active {
    border-color: #fff;
  }
  &:focus {
    color: rgba(0, 0, 0, 0.65);
  }
  &:hover {
    color: #40a9ff;
  }
  &.btn-active {
    margin-top: -1px;
    color: #40a9ff;
  }
}

[ant-click-animating-without-extra-node="true"]::after {
  opacity: 0;
  box-shadow: none;
}

.screen-options {
  visibility: hidden;
  opacity: 0;
  height: 0px;
  transition-property: height, padding;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  transition-duration: 700ms;
  background-color: #fff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-color: #f0f0f0;
  outline: 0.1rem solid transparent;
  border-radius: 2px;
  padding: 0px;
  &.show {
    padding: 24px 0;
    height: auto;
    opacity: 1;
    visibility: visible;
    transition-property: height, padding;
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
    transition-duration: 700ms;
  }
}

.order-products {
  margin-top: -1rem;
}

@media (min-width: 640px) {
  .order-products {
    margin-top: -1.5rem;
  }
}

// End Filters order

// Start Sticky Header
.sticky-right {
  border-left: 1px solid #e2e2e2;
}
.sticky-title {
  width: calc(100% - 400px);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
// End Sticky Header

// Product assortment
.p-assortment {
  overflow: scroll;
  table {
    table-layout: fixed !important;
  }
  .ant-table-expanded-row-level-1 {
    & > .ant-table-cell {
      background: transparent;
      padding: 0px;
      position: relative;
      span.pseudo {
        width: 25px;
        height: 25px;
        background-color: #eef1f5;
        position: absolute;
        top: 4px;
        left: 200px;
        transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
      }
      .wrapper-expand {
        padding-top: 17px;
        top: 0;
        overflow: auto;
      }
      .inner-expand {
        position: relative;
        background: #eef1f5;
        padding: 16px;
        display: flex;
        width: 100%;
      }
    }
  }
}
.ant-tabs-nav-wrap {
  padding-left: 10px;
}

// end product assortment

// start Tracking order
.wrapper-tracking-order {
  li::marker {
    content: "";
  }
  .btn-tracking {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    text-shadow: none;
    box-shadow: none;
    color: #52c41a;
    background-color: #f6ffed;
    border-color: #b7eb8f;
    height: 27px;
    width: 27px;
    padding: 0;
    margin-right: -2px;
    z-index: 2;
    &:hover,
    &:focus {
      color: #fff;
      background-color: rgb(0, 217, 126);
      border-color: rgb(0, 217, 126);
    }
  }
  .code-tracking {
    display: inline-block;
    margin-top: 0;
    height: 27px;
    vertical-align: top;
    padding: 0 10px;
    line-height: 25px;
    margin-right: 0;
    z-index: 1;
    // display: inline-flex;
    // align-items: center;
    // padding: 0 0.8rem;
    // border: 0.2rem solid var(--p-surface, white);
    // border-radius: 2rem;
    // font-size: 1.3rem;
    // line-height: 2rem;
    // color: var(--p-text, #454f5b);
    // font-weight: var(--p-badge-font-weight, 400);
    // background-color: var(--p-surface-success, #bbe5b3);
    // color: #414f3e;
    // mix-blend-mode: var(--p-badge-mix-blend-mode);
  }
}

// end Tracking order

// start Layout Polaris
.bm-frame {
  .Polaris-Frame__TopBar {
    box-shadow: 0 1px 5px 0 rgba(41, 85, 115, 0.21);
    .Polaris-TopBar-Menu__Activator {
      &:hover,
      &[aria-expanded="true"] {
        background-color: rgba(53, 121, 151, 0.25);
      }
    }
  }

  .secondary-menu {
    .Polaris-Button {
      max-width: 200px;
    }
    .Polaris-Button__Text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .Polaris-TopBar__LogoContainer {
    display: flex;
    flex: 0 0 24rem;
    align-items: center;
    height: 100%;
    padding: 0 0.8rem 0 1.6rem;
    flex-basis: 24rem;
    flex-basis: calc(24rem + constant(safe-area-inset-left));
    flex-basis: calc(24rem + env(safe-area-inset-left));
    padding-left: 1.6rem;
    padding-left: calc(1.6rem + constant(safe-area-inset-left));
    padding-left: calc(1.6rem + env(safe-area-inset-left));
  }

  img.Polaris-TopBar__Logo {
    background-color: #fff;
  }
}

.change-password {
  padding: 2.5rem;
  .wrap-icon {
    .Polaris-Button {
      margin-top: -1px;
    }
  }
  .btn-group {
    display: flex;
    flex-direction: row-reverse;
    .Polaris-Button--primary {
      margin-left: 15px;
    }
  }
}

.table-stores {
  .Polaris-Avatar {
    margin-top: 5px;
  }

  .Polaris-Thumbnail {
    margin-bottom: 5px;
    &:after {
      box-shadow: none;
    }
  }
}

.Polaris-Tooltip__Content {
  width: 100% !important;
  max-width: 100% !important;
}

.Polaris-Tabs__Tab:focus {
  box-shadow: none !important;
}

.Polaris-Frame__Content,
.Polaris-Modal__Body {
  .Polaris-TextField__Input {
    color: initial !important;
  }
  div[role="combobox"]:focus {
    outline: none;
  }
}

// end Layout Polaris

// Start table has select
.pm_table-has-select {
  position: relative;
  .Polaris-ResourceList-BulkActions__ButtonGroupWrapper {
    position: sticky;
    left: 1.6rem;
  }
  > .Polaris-ResourceList__ResourceListWrapper {
    overflow-x: auto;
    > * {
      min-width: 1000px;
    }
  }
  &.mapping-order {
    > .Polaris-ResourceList__ResourceListWrapper {
      overflow-x: unset;
      overflow-y: hidden;
    }
  }
  .Polaris-ResourceItem {
    cursor: default;
  }

  .Polaris-ResourceList__CheckableButtonWrapper {
    flex: 0 0 auto;
  }

  .Polaris-ResourceList__HeaderContentWrapper
    .Polaris-ResourceList-CheckableButton__Label {
    display: none;
  }

  .Polaris-ResourceList__AlternateToolWrapper {
    flex: 1 1 auto !important;
    margin-left: 0 !important;
  }

  .header-wrap {
    width: 100%;
    display: grid;
    padding-left: 2rem;
    grid-template-columns: minmax(25%, 1fr) 20% 15% 15% 10% 15%;
    grid-template-areas: "product inventory type vendor";
    > * {
      font-weight: 600;
      display: block;
    }
  }

  .Polaris-ResourceItem__Owned {
    align-self: center;
    padding-top: 0.4rem;
    .Polaris-ResourceItem__Handle {
      margin: -1.2rem 0 -1.2rem -1.2rem;
    }
  }

  .Polaris-ResourceItem__Content {
    align-self: center;
  }

  .content-wrap {
    width: 100%;
    display: grid;
    align-items: center;
    padding-left: 2rem;
    grid-template-columns: minmax(25%, 1fr) 20% 15% 15% 10% 15%;
    grid-template-areas:
      "product vendor"
      "inventory vendor"
      "type vendor"
      "channel vendor";
  }

  .Polaris-Avatar--sizeMedium {
    width: 6rem !important;
  }
  &.has-media {
    .header-wrap {
      padding-left: 23rem;
      grid-template-columns: minmax(40%, 1fr) 20% 10% 10% 10% 10%;
    }
    .content-wrap {
      align-items: flex-start;
      padding-left: 1rem;
      grid-template-columns: minmax(40%, 1fr) 20% 10% 10% 10% 10%;
      > * {
        padding-right: 2rem;
      }
    }
    .Polaris-ResourceList__ResourceListWrapper {
      > * {
        min-width: 1600px;
      }
    }
  }
}
// End table has select

// Start toggle wrap

.continue-wrap {
  .Polaris-Card {
    box-shadow: none;
    background-color: transparent;
    .Polaris-Button {
      min-height: 3rem;
      padding: 0.4rem 1.2rem;
    }
    .Polaris-Card__Section {
      padding: 0;
      .Polaris-SettingAction {
        margin: 0;
      }
      .Polaris-SettingAction__Setting {
        display: block;
        margin: 0;
      }
      .Polaris-SettingAction__Action {
        margin: 0;
      }
    }
  }
  &.form_push-product {
    .Polaris-SettingAction {
      display: block;
    }
  }
}

// End toggle wrap

// Start bulk actions product
.Polaris-ActionList {
  .Polaris-ActionList__Actions {
    #add-tags,
    #add-collections {
      border-top: 0.1rem solid var(--p-border-subdued, #dfe3e8);
    }
  }
}

// End bulk actions product

// Start Mapping orders exist product

#mp__choose-product {
  .Polaris-OptionList__Options {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    .label-wrap {
      img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        padding: 0.5rem;
      }
    }
    .Polaris-OptionList-Option {
      button {
        height: 100%;
      }
    }
  }
}
// End Mapping orders exist product

// Manual order
#mp__choose-product-3 {
  .Polaris-OptionList__Options {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    .label-wrap {
      img {
        width: 100%;
        max-height: 120px;
        height: auto;
        object-fit: cover;
        padding: 0.5rem;
      }
    }
    .Polaris-OptionList-Option {
      button {
        height: 100%;
      }
    }
  }
}

// Start notification order queues
.notifications-popover {
  min-width: 40rem;
  // min-height: 29rem;
  .notifications-heading {
    font-size: 15px;
    font-weight: 600;
  }
  .notifications-inner {
    margin: 0;
    padding: 0;
    list-style-type: none;
    li {
      display: flex;
      flex-direction: column;
      padding: 1rem 1.6rem;
      .notifications-label {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}
// End notifitcation order queues

// Start modal assgin product
.modal-image-assgin {
  z-index: 1060;
  .modal-image-assgin-inner {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
  }
}
// End modal assign product

// Start product divisions
#account-select {
  .Polaris-OptionList-Option__Label.Polaris-OptionList-Option--disabled {
    .Polaris-OptionList-Option__Checkbox {
      display: none;
    }
  }
}

// End product divisions

// Start popover filter time
#popover-filter-time {
  .actions_wrap {
    display: flex;
    flex-direction: row-reverse;
    padding-top: 1.6rem;
  }
}
// End popover filter time

// Styled option disable
.Polaris-OptionList-Option__SingleSelectOption.Polaris-OptionList-Option--disabled {
  cursor: default;
  font-weight: 600;
  padding-left: 1.2rem;
  span.heading {
    font-size: 1.2em;
  }
}

/* Fix custom column */
@media screen and (min-width: 768px) {
  .Polaris-DataTable__TableRow {
    &:hover {
      .Polaris-DataTable__Cell.custom-cell {
        background-color: var(--p-surface-hovered, #f9fafb);
      }
    }
  }

  .Polaris-DataTable__Cell {
    &.custom-cell {
      position: sticky;
      right: 0;
      z-index: 10;
      background-color: var(--p-surface-hovered, #fff);

      &:after {
        box-shadow: rgba(0, 0, 0, 0.15) -10px 0px 8px -8px inset;
        position: absolute;
        top: 0px;
        bottom: -1px;
        width: 30px;
        transition: box-shadow 0.3s ease 0s, -webkit-box-shadow 0.3s ease 0s;
        content: "";
        pointer-events: none;
        left: 0px;
        transform: translateX(-100%);
      }
    }
  }
}

/* list-store-wrap */
.list-store-wrap {
  height: 55rem !important;
  max-height: 55rem !important;
  width: 40rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #777;
}

// ::-webkit-scrollbar {
//   width: 8.5px;
//   height: 8.5px;
// }

.modal-variants-wrap {
  .ant-modal-content {
    position: relative;

    .ant-modal-footer {
      position: sticky;
      bottom: 0;
      background-color: #fff;
    }
  }
}

// Items wrap
.media-items-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 3rem;
  justify-content: flex-start;
  padding-bottom: 2rem;

  .item-wrap {
    border-radius: var(--p-border-radius-wide, 3px);
    background-color: var(--p-surface);
    box-shadow: var(
      --p-card-shadow,
      0 0 0 1px rgba(63, 63, 68, 0.05),
      0 1px 3px 0 rgba(63, 63, 68, 0.15)
    );
    outline: 0.1rem solid transparent;
    padding: 1.6rem;
    position: relative;
    cursor: pointer;
    width: calc(25% - 2.3rem);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

// Responsive
/// Screen: sm: (40em | 640px) ; md: (48em | 768px) ; lg: (64em | 1024px) ,xl: (80em | 1280px) , 2xl: (96em | 1536px);

@media (max-width: 80em) {
  .media-items-wrap {
    .item-wrap {
      width: calc(30% - 0.1rem);
    }
  }
}

@media (max-width: 48em) {
  .media-items-wrap {
    .item-wrap {
      width: calc(50% - 1.8rem);
    }
  }
}

@media (max-width: 40em) {
  .media-items-wrap {
    .item-wrap {
      width: calc(100% - 1rem);
    }
  }
}

@media (min-width: 96em) {
  .media-items-wrap {
    .item-wrap {
      width: calc(18% - 0.1rem);
    }
  }
}

.img-box-shadow {
  border-radius: var(--p-border-radius-wide, 3px);
  img {
    object-fit: cover;
    border-radius: inherit;
    box-shadow: var(
      --p-card-shadow,
      0 0 0 1px rgba(63, 63, 68, 0.05),
      0 1px 3px 0 rgba(63, 63, 68, 0.15)
    );
  }
}

// 2D mockup
.Polaris-Modal-Dialog__Modal.mockup-2d {
  .Polaris-Modal-Footer__FooterContent {
    .Polaris-Button--primary {
      position: relative;
      background: #008060;
      border-width: 0;
      border-color: transparent;
    }
  }
}

// Apply for MUI
.MuiPickersCalendarHeader-root {
  .MuiPickersCalendarHeader-labelContainer {
    font-size: 15px;
  }
}
.MuiButtonBase-root {
  font-size: 18px;

  svg {
    width: 16px;
    height: 16px;
    font-size: 24px;
  }
}

.MuiDayCalendar-header {
  .MuiTypography-caption {
    font-size: 14px;
  }
}

.MuiPickersYear-root .MuiPickersYear-yearButton {
  font-size: 15px;
}

.MuiPickersMonth-root .MuiPickersMonth-monthButton {
  font-size: 15px;
}

.MuiDayCalendar-weekContainer .MuiPickersDay-root {
  font-size: 14px;
}

.MuiTextField-root {
  width: 100%;
}

.MuiOutlinedInput-root {
  font-size: 14px !important;
}

.MuiFormControl-root .MuiInputLabel-root {
  line-height: 15px !important;
  font-size: 14px !important;
}

.MuiInputBase-root {
  font-size: 14px;
  line-height: 23px;
}

.MuiInputBase-root {
  input {
    height: 16px;
    padding: 14px 14px;
    line-height: 16px;
  }
}

// End for MUI

#modal-image-wrap .media-wrapper img,
#media-library .image-inner,
.related-design_wrap img,
.task-compare-item .modal-image img,
.design-wrap .modal-image img,
.design-position_wrap .file_wrap .file_inner,
.file-detail_wrap .thumbnail img,
.image-zoom-in-out-wrap img,
.ant-upload-list img,
.preview-file img,
.thumbnail_content img,
.view-origin-file img {
  background-color: var(--omg-custom-bg, inherit) !important;
}

/* Import custom style and responsive, please keep this in the bottom */
@import "custom.scss";
@import "responsive.scss";
@import "main.scss";
// @import "index.css"
