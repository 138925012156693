body {
  .ant-form-explain {
    font-style: italic;
    font-size: 0.75rem;
    color: #f56565;
    margin: 12px 0 0;
  }

  .ant-form-item-required::before {
    display: none;
  }

  .ant-form-item label {
    margin-bottom: 0.5rem;
    // font-size: 0.875rem;
    font-size: 14px;
    font-weight: 500;
  }

  .ant-form-item-label {
    line-height: normal;
    padding-bottom: 8px;
  }

  .ant-form-item-label > label {
    height: 23px;
  }

  // .ant-input {
  //   box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  // }

  .ant-form-item {
    margin-bottom: 15px;

    .ant-form-item-label {
      padding-bottom: 5px;

      label {
        &:after {
          display: none;
        }
      }

      .ant-form-item-required {
        padding-right: 10px;

        &:before {
          content: "*";
          color: red;
          position: absolute;
          right: 0px;
          top: 0px;
          display: block;
        }
      }
    }

    .has-error .ant-form-explain {
      margin: 0;
    }
  }

  .ant-comment-inner {
    padding: 10px 0;
  }

  .ant-modal-content {
    border-radius: 2px;
  }

  .ant-modal-body strong {
    display: flex;
    align-items: center;
  }

  .ant-modal-body strong p {
    padding: 8px 0;
    margin-left: 20px;
    position: relative;
  }

  .ant-modal-body .text-body {
    margin-left: 40px;
  }

  .ant-col.ant-form-item-label.ant-col-xs-24.ant-col-sm-14 {
    padding-top: 10px;
    text-align: left;
  }

  .ant-form-item-label > label.ant-form-item-required::before {
    line-height: 1.5;
  }

  .ant-menu-submenu-popup > .ant-menu {
    background: #fff;
  }

  .ant-card-bordered,
  .ant-card-head,
  .ant-select-selection {
    border-color: #f0f0f0;
  }

  /* Button */
  // .ant-btn:not(.ant-btn-link) {
  //     &:hover, &:active, &:focus {
  //         color: #3545AC;
  //         border-color: #3545AC;
  //     }
  // }
  // .ant-btn.ant-btn-primary {
  //     background-color: #4959bd;
  //     border-color: #4959bd;
  //     color: #FFF;
  //     &:hover, &:active, &:focus {
  //         background-color: #3545AC;
  //         border-color: #3545AC;
  //         color: #FFF;
  //     }
  // }
}

.ant-drawer-content-wrapper {
  width: 450px !important;
  @media screen and (max-width: 678px){
    width: 320px !important;
  }
}

.iZEukV .product-base-list .product-base .ant-avatar-square {
  border-radius: 0;
  padding: 3px;
}

i.anticon.anticon-check-circle {
  margin-bottom: 40px;
}

.ant-upload.ant-upload-select-picture-card {
  margin-top: 12px !important;
  width: 90px;
  height: 90px;
}

.ant-upload-list-picture-card .ant-upload-list-item {
  width: 90px;
  height: 90px;
  margin-top: 12px;
}

.sc-fzXfNS.eBJqNk.container.enabled {
  width: 200px;
  height: 200px;
}

.sc-fzXfNS.eBJqNk.container.disabled {
  height: 200px;
  width: 200px;
}

.image-ig {
  display: flex;
  height: 70px;
  justify-content: start;
  align-items: center;
}

th.image-ig.ant-table-row-cell-break-word {
  height: 46px;
}

tbody.ant-table-tbody {
  background-color: #fff;
}

.p-table {
  .ant-table-thead > tr > th[colspan]:not([colspan="1"]) {
    text-align: left;
  }

  .ant-table-default .ant-table-thead > tr > th {
    padding: 0px 16px;
  }

  span.ant-table-header-column {
    padding-top: 10px;
  }
}

.ant-modal-close {
  position: absolute;
  top: -5px;
  right: -5px;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

.ant-modal-footer .footer-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.custom-form {
  max-width: 700px;
  margin: auto;
}

.comment-bg .ant-modal-content {
  background-color: #f4f5f7 !important;
}

.mb-logo {
  max-width: 180px;
}

// Filter Container
.filter-container {
  padding: 24px;
}
.selected-actions {
  padding: 0px 24px 24px;
}

.mb-mapping-active-items {
	top: 0;
	left: 0;
	margin: 0;
	position:fixed;
	
	.ant-modal-body {
		height: calc(100vh - 110px);
		overflow-y:auto;
	}
	.ant-collapse-content-box {
		overflow:hidden;
	}
	.mb-w-50 {
		width: 50%;
		float: left;
	}
}
.mb-simple-panels {
	.mb-simple-label {
		display: block;
		border-bottom: 1px solid #f0f0f0;
		padding-bottom: 5px;
	}
	.mb-simple-options {
		overflow:hidden;
		margin-top: 20px;
	}
}

.Polaris-Stack__Item {
	.ant-picker {
		width: 100%;
		padding: 8px 10px;
	}
}